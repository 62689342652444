/* eslint-disable no-console */

import { register } from "register-service-worker";
import { Notification } from 'element-ui';
let foundNotification = null
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      foundNotification = Notification({
        title: "发现新版本",
        message: "正在载入新版本...",
        type: "info",
        duration: 0
      })
    },
    updated() {
      foundNotification.close()
      Notification({
        title: "更新提示",
        message: "新版本下载成功！下次打开网站的时候将自动载入更新；如果需要现在更新，请关闭浏览器或标签页后重新打开网站",
        type: "success",
        duration: 0
      })
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
