import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "HomeManager";
const RouteModules = [
  {
    path: "/contactUs",
    redirect: "/contactUs/main",
    name: "ContactUsManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "联系我们管理",
    iconSvg: "user",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/contactUs/main",
        name: "ContactUs",
        component: () => import("@/views/contactus/Index.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "联系我们",
          keepAlive: true,
          iconSvg: "contact",
          iconFont: "",
          roles: []
        }
      }
    ]
  }
];

export default RouteModules;
