/* eslint-disable */

import regions from "@/assets/areas/regions.json";
import cities from "@/assets/areas/cities.json";
import states from "@/assets/areas/states.json";
import countries from "@/assets/areas/countries.json";
import areaCascader from "@/assets/areas/areaCascader.json";
let chinaCascaderOptions = areaCascader.filter(item => item.id === "44");

const state = {
    userOptions: [],
    regionOptions: regions["RECORDS"],
    cityOptions: cities["RECORDS"],
    provinceOptions: states["RECORDS"],
    countryOptions: countries["RECORDS"],
    areaCascaderOptions: areaCascader,
    chinaCascaderOptions: chinaCascaderOptions,

}

const mutations = {

}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
