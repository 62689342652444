import { Notification } from "element-ui";
let errorCodes = [401, 500]
export const ShowApiError = (title = "请求错误", err) => {

  if (errorCodes.includes(err.status)) {
    return err
  }

  Notification.error({
    title: title,
    position: "bottom-right",
    duration: 1500,
    dangerouslyUseHTMLString: true,
    message: `<p>状态码：${err.status} </p>
        <p>错误类型：${err.error} </p>
        <p>错误信息：${err.message} </p>`
  });
};

export function commonError(title = "请求错误", err) {
  Notification.error({
    title: title,
    position: "bottom-right",
    duration: 1500,
    dangerouslyUseHTMLString: true,
    message: `<p>状态码：${err.status} </p>
        <p>错误类型：${err.error} </p>
        <p>错误信息：${err.message} </p>`
  });
}
