import Vue from "vue";
import VueRouter from "vue-router";
import BasicLayout from "../layouts/BasicLayout.vue";
Vue.use(VueRouter);


import users from "./modules/users";
import product from "./modules/product";
import websiteMessage from "./modules/websiteMessage";
import home from "./modules/home";
import aboutUs from "./modules/aboutUs";
import contactUs from "./modules/contactUs";
import blogs from "./modules/blogs";
// import pageManager from "./modules/pageManager";


export const contantsRoutes = [
  {
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/index'),
    meta: {
      module: "",
      isMenu: false,
      title: "重定向",
      affix: false,
      keepAlive: false,
      iconSvg: "",
      iconFont: ""
    }
  },
  {
    path: "/login",
    name: "MainLogin",
    component: () => import("@/views/login"),
    meta: {
      module: "",
      isMenu: false,
      title: "登录",
      keepAlive: true,
      iconSvg: "poster",
      iconFont: ""
    }
  },
  {
    path: "/",
    name: "LayoutHome",
    redirect: "/dashboard",
    component: BasicLayout,
    showChildren: false,
    onlyExtentdLayout: true,
    children: [
      {
        path: "/dashboard",
        name: "DashboardIndex",
        component: () => import("@/views/dashboard/Index.vue"),
        meta: {
          module: "",
          isMenu: true,
          title: "网站配置",
          affix: true,
          keepAlive: true,
          iconSvg: "home",
          iconFont: ""
        }
      }
    ]
  }
];

export const asyncRoutes = [
  ...home,
  ...blogs,
  ...product,
  ...aboutUs,
  ...contactUs,
  ...users,
  ...websiteMessage,
  // ...pageManager,

  // ...iconManager,
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/error/401.vue"),
    meta: {
      module: "error",
      isMenu: false,
      title: "401",
      keepAlive: true,
      iconClass: "404"
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error/404.vue"),
    meta: {
      module: "error",
      isMenu: false,
      title: "404",
      keepAlive: true,
      iconClass: "404"
    }
  },
  {
    path: "*",
    redirect: "/404",
    meta: {
      module: "error",
      isMenu: false,
      title: "*",
      keepAlive: true,
      iconClass: "404"
    }
  }
];

const router = new VueRouter({
  routes: contantsRoutes,
  // scrollBehavior(to, from, savedPosition) {
  //   console.log('savedPosition', savedPosition)
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // }
});

// 重置路由
export function resetRouter() {
  const newRouter = new VueRouter({
    routes: contantsRoutes
  });
  router.matcher = newRouter.matcher; // reset router
}

export default router;
