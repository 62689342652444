const getters = {
  roles: state => state.auth.roles,
  userInfo: state => state.auth.userInfo,
  hasUserInfo: state => state.auth.hasUserInfo,
  site_title: state => state.app.site_title,
  isSideBarCollapse: state => state.app.isSideBarCollapse,
  tagViews: state => state.tagsView.tagViews,
  showActiveDialog: state => state.auth.showActiveDialog,
};
export default getters;
