import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
  {
    path: "/users",
    redirect: "/users/list",
    name: "UserManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "用户管理",
    iconSvg: "user",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/users/list",
        name: "UsersList",
        component: () => import("@/views/users/UsersList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "用户管理",
          keepAlive: true,
          iconSvg: "user",
          iconFont: "",
          roles: ['USER_ADMIN']
        }
      }
    ]
  }
];

export default RouteModules;
