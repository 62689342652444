"use strict";
/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import API_CONFIG from "./api.config";
import { logout, getToken, relogin } from "@/utils/auth";
import { commonError } from "./error";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let apiurl = process.env.NODE_ENV === "production" ? API_CONFIG.BASE_V1_API : API_CONFIG.BASE_V1_TEST_API;

let config = {
    baseURL: apiurl,
    timeout: 60 * 5000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const apiv1 = axios.create(config);

apiv1.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers.Authorization = getToken();
            // config.headers['referrerPolicy'] = "no-referrer-when-downgrade";

        }
        return config;
    },
    error => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
apiv1.interceptors.response.use(
    response => {
        // Do something with response data
        return response;
    },
    error => {
        if (error.response.status === 401) {
            commonError("401错误", error.response.data)
            logout()
            // relogin()
        }
        if (error.response.status === 404) {
            commonError("404错误", error.response.data)
        }
        if (error.response.status >= 500) {
            commonError("服务器错误", error.response.data)
        }
        return Promise.reject(error.response.data);
    }
);
export {
    apiv1
}

Plugin.install = function (Vue, options) {
    Vue.apiv1 = apiv1;
    window.apiv1 = apiv1;
    Object.defineProperties(Vue.prototype, {
        apiv1: {
            get() {
                return apiv1;
            }
        },
        $apiv1: {
            get() {
                return apiv1;
            }
        }
    });
};

Vue.use(Plugin);

export default Plugin;
