<template>
  <div id="tags-view-container" class="tags-view-container">
    <scroll-pane
      ref="scrollPane"
      class="tags-view-wrapper"
      @scroll="handleScroll"
    >
      <router-link
        v-for="tag in visitedViews"
        ref="tag"
        :key="tag.path"
        :class="isActive(tag) ? 'active' : ''"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        tag="span"
        class="tags-view-item"
        @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''"
        @contextmenu.prevent.native="openMenu(tag, $event)"
      >
        <span class="tag-icon">
          <svg-icon :iconClass="tag.meta.iconSvg"></svg-icon>
        </span>
        <span v-if="tag.params && tag.params.id">{{ tag.params.id }} - </span>
        <span>{{ tag.meta.title }}</span>
        <span v-if="tag.query && tag.query.name"> - {{ tag.query.name }}</span>
        <span
          v-if="!isAffix(tag)"
          class="el-icon-close"
          @click.prevent.stop="closeSelectedTag(tag)"
        />
      </router-link>
    </scroll-pane>
    <ul
      v-show="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <li @click="refreshSelectedTag(selectedTag)">刷新</li>
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">
        关闭
      </li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags(selectedTag)">关闭全部</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from "./ScrollPane";
// import path from "path";

export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes() {
      return this.$store.state.app.menus;
    },
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + +new Date()
        : this.$route + +new Date();
    },
  },
  watch: {
    $route() {
      this.addTags();
      this.moveToCurrentTag();
    },
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
  },
  mounted() {
    this.initTags();
    this.addTags();
  },
  methods: {
    isActive(route) {
      return route.path === this.$route.path;
    },
    isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    filterAffixTags(routes) {
      let affixTags = [];
      routes.forEach((route) => {
        if (route.meta) {
          // 不继承布局的单个菜单
          if (route.meta.isMenu && route.meta.affix) {
            let tagPath = route.fullPath ? route.fullPath : route.path;
            affixTags.push({
              fullPath: tagPath,
              path: tagPath,
              name: route.name,
              meta: { ...route.meta },
            });
          }
        } else {
          // 继承了布局，但是属于单个菜单的
          if (
            route.children &&
            route.children.length > 0 &&
            route.onlyExtentdLayout
          ) {
            let target = route.children[0];
            let tagPath = target.fullPath ? target.fullPath : target.path;
            if (target.meta.isMenu && target.meta.affix) {
              affixTags.push({
                fullPath: tagPath,
                path: tagPath,
                name: target.name,
                meta: { ...target.meta },
              });
            }
          }
        }

        // 菜单分组
        if (route.children && route.showChildren) {
          let tempTags = this.filterAffixTags(route.children);
          affixTags = [...affixTags, ...tempTags];
        }
      });
      return affixTags;
    },
    initTags() {
      const affixTags = this.filterAffixTags(this.routes);
      this.affixTags = affixTags;
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch("tagsView/addVisitedView", tag);
        }
      }
    },
    addTags() {
      if (this.$route.name && this.$route.meta) {
        this.$store.dispatch("tagsView/addView", this.$route);
      }
      return false;
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag;
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag);
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch("tagsView/updateVisitedView", this.$route);
            }
            break;
          }
        }
      });
    },
    refreshSelectedTag(view) {
      this.$store.dispatch("tagsView/delCachedView", view).then(() => {
        const { fullPath } = view;
        this.$nextTick(() => {
          this.$router.replace({
            path: "/redirect" + fullPath,
          });
        });
      });
    },
    closeSelectedTag(view) {
      this.$store
        .dispatch("tagsView/delView", view)
        .then(({ visitedViews }) => {
          if (this.isActive(view)) {
            this.toLastView(visitedViews, view);
          }
        });
    },
    closeOthersTags() {
      if (this.selectedTag.path !== this.$route.path) {
        this.$router.push(this.selectedTag);
      }
      this.$store
        .dispatch("tagsView/delOthersViews", this.selectedTag)
        .then(() => {
          this.moveToCurrentTag();
        });
    },
    closeAllTags(view) {
      this.$store.dispatch("tagsView/delAllViews").then(({ visitedViews }) => {
        if (this.affixTags.some((tag) => tag.path === view.path)) {
          return;
        }
        this.toLastView(visitedViews, view);
      });
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === "Dashboard") {
          // to reload home page
          this.$router.replace({ path: "/redirect" + view.fullPath });
        } else {
          this.$router.push("/");
        }
      }
    },
    openMenu(tag, e) {
      console.log("e", e);
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft + 15; // 15: margin right
      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      if (this.isAffix(tag)) {
        this.top = e.clientY - 120;
      } else {
        this.top = e.clientY - 150;
      }

      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu() {
      this.visible = false;
    },
    handleScroll() {
      this.closeMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
$tag-view-height: 40px;
$tag-view-item-height: 30px;
.tags-view-container {
  height: $tag-view-height;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  .tags-view-wrapper {
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: $tag-view-item-height;
      line-height: $tag-view-item-height;
      transition: all 0.3s linear;
      color: #333;
      background: #fff;
      padding: 0 10px;
      font-size: 12px;
      .tag-icon {
        color: #333;
        padding-right: 5px;
      }
      // &:not(:last-of-type) {
      //   border-right: 1px solid transparent;
      // }
      &:hover {
        // background-color: $theme_hover_color;
        color: $theme_hover_color;
        .tag-icon {
          color: $theme_hover_color;
        }
      }
      &.active {
        // background-color: #f1f1f1;
        // border-bottom: 1px solid transparent;
        color: $theme_hover_color;
        font-weight: bolder;
        .tag-icon {
          color: $theme_hover_color;
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      vertical-align: -2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      overflow: hidden;
      padding-left: 8px;
      // &:before {
      //   transform: scale(.6);
      //   display: inline-block;
      //   vertical-align: -3px;
      // }
      &:hover {
        color: #f14545;
      }
    }
  }
}
</style>
