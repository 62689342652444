<template>
  <div class="layout">
    <div class="layout-container">
      <div
        class="layout__left"
        :class="[isCollapse ? 'layout__left--active' : '']"
      >
        <div class="layout__logo" @click="$router.push('/')">
          <Logo :url="Logo" v-if="!isCollapse" style="max-height: 100%" />
          <Logo :url="MINI_LOGO" v-if="isCollapse" style="max-width: 60%" />
        </div>
        <SideBar />
        <div class="sidebar-toggle" @click="ToggleSideBarCollapse">
          <i :class="[isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']"></i>
        </div>
      </div>
      <div
        class="layout__right"
        :class="[isCollapse ? 'layout__right--active' : '']"
      >
        <LayoutHeader class="layout__right--header" />
        <div class="layout__content">
          <transition name="el-fade-in-linear">
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
          </transition>
          <transition name="el-fade-in-linear">
            <router-view v-if="!$route.meta.keepAlive" :key="key"></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./components/SideBar";
import LayoutHeader from "./components/Header";
import Logo from "@/components/Logo";
import { mapMutations } from "vuex";
import mini_logo from "@/assets/mini-logo.png";
import logo from "@/assets/logo.png";
import { setFontClass } from "@/utils/common";
export default {
  components: { SideBar, LayoutHeader, Logo },
  data() {
    return {
      MINI_LOGO: mini_logo,
      Logo: logo,
    };
  },
  computed: {
    isCollapse() {
      return this.$store.state.app.isSideBarCollapse;
    },
    key() {
      return this.$route.path + new Date();
    },
  },
  mounted() {
    this.setSideBarMode();
    setFontClass();
  },
  methods: {
    ...mapMutations({
      ToggleSideBarCollapse: "app/ToggleSideBarCollapse",
      CloseSideBarCollapse: "app/CloseSideBarCollapse",
    }),
    setSideBarMode() {
      let that = this;
      let white = document.body.clientWidth;
      if (white < 1200 && !this.isCollapse) {
        that.CloseSideBarCollapse();
      }
      window.addEventListener("resize", function() {
        let white = document.body.clientWidth;
        if (white < 1200 && !this.isCollapse) {
          that.CloseSideBarCollapse();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
$base-width: 200px;
$sibebar-toggle-btn-height: 50px;
$base-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
$header-height: 40px;
$tag-view-height: 40px;
$layout-logo-height: 40px;
$base-border-radius: 10px;
$base-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
.layout {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // padding: 10px 10px;
  position: relative;
  // background-color: rgb(119, 52, 96);
  overflow: hidden;
  .layout-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    // border-radius: $base-border-radius;
    overflow: hidden;
  }
  &__logo {
    box-sizing: border-box;
    width: 100%;
    padding: 4px 10px;
    height: $layout-logo-height;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }
  .layout__left {
    box-sizing: border-box;
    width: $base-width;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    z-index: 2;

    box-shadow: $base-shadow;
    padding-top: $layout-logo-height;
    padding-bottom: $sibebar-toggle-btn-height;
    overflow-x: hidden;
    background-color: $theme_color;
    box-shadow: $base-box-shadow;
    &--active {
      width: 64px;
    }
    .sidebar-toggle {
      box-sizing: border-box;
      // padding-right: 20px;
      width: 100%;
      height: $sibebar-toggle-btn-height;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 25px;
      color: rgb(243, 242, 242);
      border-top: 1px solid $theme_border_color;
      cursor: pointer;
      &:hover {
        color: rgb(242, 235, 247);
        background-color: $custom-menu-hover-color;
      }
      i {
        transition: all 0.3s linear;
      }
      &:active i {
        transform: scale(1.2);
      }
    }
  }
  .layout__right {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: $base-width;
    bottom: 0;
    right: 0;
    padding-top: $header-height;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    &--header {
      box-sizing: border-box;
      width: 100%;
      height: $header-height;
      position: absolute;
      top: 0;
      z-index: 1;
      // background-color: #fff;
    }
    &--active {
      left: 64px;
    }
  }
  .layout__content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #e7e7e7;
    position: relative;
  }
}
</style>
