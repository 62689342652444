import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
  {
    path: "/blogs",
    redirect: "/blogs/list",
    name: "BlogsManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "BLOGS管理",
    iconSvg: "log",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/blogs/list",
        name: "BlogsList",
        component: () => import("@/views/blogs/BlogsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "BLOGS",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['NEWS_ADMIN']
        }
      },
      {
        path: "/blogs/edit/:id?",
        name: "BlogForm",
        component: () => import("@/views/blogs/BlogForm.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "BLOG-EDIT",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['NEWS_ADMIN']
        }
      }
    ]
  }
];

export default RouteModules;
