import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "WebsiteMessageManager";
const RouteModules = [
  {
    path: "/website/message",
    redirect: "/website/message/list",
    name: "MessageManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "留言管理",
    iconSvg: "followRecord",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/website/message/list",
        name: "WebsiteMessages",
        component: () => import("@/views/websiteMessages/WebsiteMessagesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "网站留言",
          keepAlive: true,
          iconSvg: "questions",
          iconFont: "",
          roles: []
        }
      }
    ]
  }
];

export default RouteModules;
