import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "HomeManager";
const RouteModules = [
  {
    path: "/home",
    redirect: "/home/main",
    name: "Companies",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "首页管理",
    iconSvg: "statisticAnalysis",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/home/main",
        name: "Home",
        component: () => import("@/views/home/Home.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "首页",
          keepAlive: true,
          iconSvg: "statisticAnalysis",
          iconFont: "",
          roles: []
        }
      }
    ]
  }
];

export default RouteModules;
