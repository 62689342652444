/* eslint-disable */

import { getToken } from "@/utils/auth";
const state = {
	site_title: "此处是网站的标题",
	menus: [],
	isSideBarCollapse: false,
	codeToExport: false,
	version: '',

}

const mutations = {

	SetSiteTitle(state, payload) {
		if (typeof payload === 'string') {
			state.site_title = payload
		}
	},
	SetMenus(state, payload) {
		if (payload.length > 0) {
			state.menus = payload
		}
	},
	setVersion(state, payload) {
		state.version = payload
	},
	SetCodeToExport(state, payload) {
		state.codeToExport = payload
	},
	ToggleSideBarCollapse(state) {
		state.isSideBarCollapse = !state.isSideBarCollapse
	},
	CloseSideBarCollapse(state) {
		state.isSideBarCollapse = true
	},
	OpenSideBarCollapse(state) {
		state.isSideBarCollapse = false
	}
}

const actions = {


}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
