/* eslint-disable */
import { apiv1 } from "@/request/apiv1";
import router, { asyncRoutes, resetRouter } from "@/router";
import store from "@/store";
import BasicConfig from "@/config";
import API from "@/request/api.config.js";

/**
 * @param {string} token
 */
export function setToken(token) {
  localStorage.setItem(`${BasicConfig.BRAND_NAME_EN}_token`, JSON.stringify(token));
}
export function getToken() {
  return JSON.parse(localStorage.getItem(`${BasicConfig.BRAND_NAME_EN}_token`));
}
export function clearToken() {
  localStorage.removeItem(`${BasicConfig.BRAND_NAME_EN}_token`);
}

export function GetUserInfo(token) {
  return new Promise((resolve, reject) => {
    if (token === getToken()) {
      apiv1
        .get("/users/self")
        .then(res => {
          if (res.status === 200) {
            const { roles, departmentId } = res.data;
            let roleIds = roles.map(item => item.id);
            let userRoles = [...roleIds, departmentId]
            store.commit('auth/SET_USER_INFO', res.data)
            store.commit('auth/SET_ROLES', userRoles)
            if (!res.data.activated) {
              store.commit('auth/TOGGLE_ACTIVE_DIALOG', true)
            }
            resolve(userRoles);
          }
        })
        .catch(err => {
          reject(err)
        });
    } else {
      reject(err)
    }
  });
}

export function RequstToken(username = "", password = "") {
  return new Promise((resolve, reject) => {
    apiv1
      .post(`/auth/login`, {
        username: username,
        password: password
      })
      .then(res => {
        if (res.status === 201 || res.status === 202) {
          resolve(res);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}



export function PostCodeByPhoneNumber(phoneNo = "") {
  return new Promise((resolve, reject) => {
    apiv1
      .post(`/sms/code`, {
        phoneNo
      })
      .then(res => {
        if (res.status === 201 || res.status === 202) {
          resolve(res);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function PutPhoneNumberVerify(phoneNo = "", code = "") {
  return new Promise((resolve, reject) => {
    apiv1
      .put(`/sms/code/verify`, {
        phoneNo, code
      })
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          resolve(res);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}


/**
 * Use meta.role to determine if the current user has permission
 * @param roles 用户拥有的权限数组
 * @param route 单个路由
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles && route.meta.roles.length > 0) {
    return roles.some(role => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

/**
 * Use meta.role to determine if the current user has permission
 * @param route 当前路由
 * @param username 用户名
 */
function isInBlackList(route, username) {
  if (route.meta && route.meta.blackLists && route.meta.blackLists.length > 0) {
    return route.meta.blackLists.includes(username);
  } else {
    return false;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = [];
  let username = store.getters.userInfo.id ? store.getters.userInfo.id : ''
  routes.forEach(route => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp) && !isInBlackList(tmp, username)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

/**
 * @param {Array} roles
 * 根据用户全权限生成路由,如果是admin,则把所有的异步路由加载，不用过滤
 */
export function generateRoutes(roles) {
  return new Promise(resolve => {
    let accessedRoutes;
    if (roles.includes("admin")) {
      accessedRoutes = ayscRoutes || [];
    } else {
      accessedRoutes = filterAsyncRoutes(ayscRoutes, roles);
    }
    resolve(accessedRoutes);
  });
}


/**
 *
 * @登出
 */
export function logout() {
  if (localStorage.getItem("Trufflepig_token")) {
    localStorage.removeItem("Trufflepig_token")
  }
  if (localStorage.getItem("Trufflepig_current_route")) {
    localStorage.removeItem("Trufflepig_current_route")
  }
  ClearCurrentRoute();
  clearToken();
  store.commit('auth/SET_USER_INFO', {})
  store.commit('auth/SET_ROLES', [])
  store.commit('tagsView/DEL_ALL_VISITED_VIEWS')
  store.commit('tagsView/DEL_ALL_CACHED_VIEWS')
  resetRouter();
  return true;
}

/**
 * @param {Array} targetRoles 目标数组
 * 检验规则，遍历目标数组里面的元素，如果目标数组里面至少有一个元素存在于源数组中，
 * 则代表有合法，有权限，否则就没有权限
 */
export function checkPermission(targetRoles = []) {
  return targetRoles.some(role => store.getters.roles.includes(role));
}


export const whiteList = ['/login'];

export const relogin = () => {
  router.replace({
    path: "/login",
    query: { message: '登录过期,请重新登录!' }
  });
};

/**
 * @param {Object} route 当前页面访问的路由
 */
export function SetCurrentRoute(route) {
  localStorage.setItem(`${BasicConfig.BRAND_NAME_EN}_current_route`, JSON.stringify(route))
}
export function GetCurrentRoute() {
  return JSON.parse(localStorage.getItem(`${BasicConfig.BRAND_NAME_EN}_current_route`))
}
export function ClearCurrentRoute() {
  localStorage.removeItem(`${BasicConfig.BRAND_NAME_EN}_current_route`)
}

export function GetImgPath(basePath = "", fileName = "", token = getToken()) {
  return `${basePath}${fileName}?access_token=${token.replace('Bearer ', "")}`
}

export function GetFilePath(basePath = "", fileName = "", token = getToken()) {
  return `${basePath}${fileName}?access_token=${token.replace('Bearer ', "")}`
}

export function GetFileById(id = "", token = getToken()) {
  return API.BASE_V1_API + `/files/${id}/download?access_token=${token.replace('Bearer ', "")}`;
}


/**
 * 刷新当前页面
 */
export function onRefreshCurrentPage(fullPath = "") {
  router.replace({
    path: "/redirect" + fullPath,
  });
}
