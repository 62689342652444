/* eslint-disable */
const state = {
	roles: [],
	indexPath: "",
	userInfo: {},
	hasUserInfo: false,
	showActiveDialog: false,
}

const mutations = {
	SET_USER_INFO(state, payload) {
		if (payload && payload !== null) {
			let arr = Object.keys(payload)
			if (arr.length <= 0) {
				state.hasUserInfo = false
				state.userInfo = {}
			} else {
				state.userInfo = payload
				state.hasUserInfo = true
			}
		} else {
			state.hasUserInfo = false
			state.userInfo = {}
		}
	},
	SET_ROLES(state, payload) {
		state.roles = payload
	},
	SET_INDEX_PATH(state, payload) {
		state.indexPath = payload
	},
	TOGGLE_ACTIVE_DIALOG(state, payload){
		state.showActiveDialog = payload
	}

}

const actions = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
