import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "HomeManager";
const RouteModules = [
  {
    path: "/aboutUs",
    redirect: "/aboutUs/main",
    name: "AboutUSManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "首页管理",
    iconSvg: "user",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/aboutUs/main",
        name: "AboutUS",
        component: () => import("@/views/aboutus/Index.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "关于我们",
          keepAlive: true,
          iconSvg: "invitations",
          iconFont: "",
          roles: []
        }
      }
    ]
  }
];

export default RouteModules;
