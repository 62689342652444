import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "ProductManager";
const RouteModules = [
  {
    path: "/products",
    redirect: "/prosuct/series/list",
    name: "Products",
    component: BasicLayout,
    showChildren: true,
    groupTitle: "产品管理",
    iconSvg: "departmentManager",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/product/list",
        name: "ProductsList",
        component: () => import("@/views/products/ProductsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "所有产品",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/form/:id?",
        name: "ProductFormPage",
        component: () => import("@/views/products/ProductFormPage.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "产品编辑",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/specifications/list",
        name: "ProductSpecificationsList",
        component: () => import("@/views/productSpecifications/ProductSpecificationsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品规格",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      }, {
        path: "/product/techniques/list",
        name: "ProductTechniquesList",
        component: () => import("@/views/productTechniques/ProductTechniquesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品工艺",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/series/list",
        name: "ProductSeriesList",
        component: () => import("@/views/productSeries/ProductSeriesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品系列",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },

      {
        path: "/product/styles/list",
        name: "ProductStylesList",
        component: () => import("@/views/productStyles/ProductStylesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品风格",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/colors/list",
        name: "ProductColorsList",
        component: () => import("@/views/productColors/ProductColorsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品色系",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/height/list",
        name: "ProductHeightsList",
        component: () => import("@/views/productHeights/ProductHeightsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品厚度",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },

      {
        path: "/product/materials/list",
        name: "ProductMaterialsList",
        component: () => import("@/views/productMaterials/ProductMaterialsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品材质",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      }

    ]
  }
];

export default RouteModules;
